/*
 * Copyright 2023 Thoughtworks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@import "../../global/common";

.parent {
  display: flex;
  flex-flow: row nowrap;
  place-content: space-between flex-start;
  align-items: stretch;

  .stage-job-tree {
    overflow: scroll;
    width: 200px;
    flex: 0 1 auto;
    align-self: auto;

    li {
      float: unset;
    }
  }

  .stage-or-job {
    margin-left: 10px;
    flex: 2 1 auto;
    align-self: stretch;
  }
}

.properties-wrapper {
  li {
    float: unset;
  }
}

.light-color {
  color: lighten($text-color, 30%);
}

.task-list {
  > div {
    padding: 5px;
    border-bottom: 1px solid $border-color;
  }
}

.task-row {
  display: flex;
  flex-flow: row nowrap;
  place-content: stretch flex-start;
  align-items: stretch;

  .task-description {
    overflow: scroll;
    flex: 1 1 auto;
    align-self: stretch;
  }

  .task-run-if {
    height: 80%;
    line-height: 80%;
    font-size: 80%;
    white-space: nowrap;
    flex: 0 1 auto;
    align-self: auto;
    background-color: $secondary-bg;
    padding: 5px;
    border: 1px solid $global-border-color;
    border-radius: $global-border-radius;
  }
}

.icon-delete {
  @include icon-before($type: $fa-var-xmark);

  border: none;
  display: inline;
  cursor: pointer;
  width: 22px;
  height: 22px;
  padding: 0;

  &::before {
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}

.switch-wrapper {
  margin-bottom: 25px;
}

.permission-name-wrapper {
  margin-top: 10px;
  margin-bottom: -10px;
}

.permission-checkbox-wrapper {
  display: flex;
  margin-top: 15px;
}

.cancel-button-wrapper {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}
